import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useContainerDimensions, useInterval, usePrevious } from '../../_hooks';
import { Icon } from '../../_shared';

import './carousel.scss';

type TProps = {
  autoPlay?: boolean;
  emptyLabel: string;
  slides: ReactNode[];
};

/**
 * EC Component: Carousel
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-carousel--default
 * js: https://github.com/ec-europa/europa-component-library/tree/v3-dev/src/implementations/vanilla/components/carousel
 */

const TRANSITION_TIMING = '0.4s';
const AUTOPLAY_TIMING = 10000;

const Carousel: FC<TProps> = ({ slides = [], autoPlay = false, emptyLabel }) => {
  const { t } = useTranslation();
  const slidesContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(1);
  const prevIndex = usePrevious(currentIndex);
  const isEmpty = !slides?.length;
  const [isAutoPlaying, setIsAutoPlaying] = useState(autoPlay && !isEmpty);
  const { width: containerWidth } = useContainerDimensions(slidesContainerRef);

  const carouselSlides = [slides[slides.length - 1], ...slides, slides[0]];
  const carouselSlidesTotal = carouselSlides.length;
  const currentSlideNumber = currentIndex === 0 ? slides.length : currentIndex > slides.length ? 1 : currentIndex;
  const hasNoTransition =
    (currentIndex === 1 && prevIndex === carouselSlidesTotal - 1) ||
    (currentIndex === carouselSlidesTotal - 2 && prevIndex === 0);

  // Autoplay
  const { start, stop } = useInterval(() => {
    shiftSlide('next', false);
  }, AUTOPLAY_TIMING);

  useEffect(() => {
    if (isAutoPlaying) {
      start();
    } else {
      stop();
    }
  }, [isAutoPlaying]);

  // Transition handling
  useEffect(() => {
    const handleTransitionEnd = () => {
      setCurrentIndex(value => {
        if (value === 0) return carouselSlidesTotal - 2;
        if (value === carouselSlidesTotal - 1) return 1;
        return value;
      });
    };

    if (slidesContainerRef?.current) {
      slidesContainerRef.current.addEventListener('transitionend', handleTransitionEnd);
    }
    return () => {
      if (slidesContainerRef?.current) {
        slidesContainerRef.current.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [slidesContainerRef.current]);

  // Move to next/previous slide
  const shiftSlide = (direction: 'next' | 'previous', stopAutoPlay = true) => {
    setCurrentIndex(value => {
      if (direction === 'next') {
        return value + 1 === carouselSlidesTotal ? 0 : value + 1;
      }
      return value === 0 ? carouselSlidesTotal - 1 : value - 1;
    });
    setIsAutoPlaying(!stopAutoPlay);
  };

  return (
    <div className="ecl-carousel" data-ecl-auto-init="Carousel">
      <div className="ecl-carousel__container" ref={slidesContainerRef}>
        <div
          className="ecl-carousel__slides"
          id="ecl-carousel-slider"
          style={{
            left: isEmpty ? '0' : `-${containerWidth * currentIndex}px`,
            transitionDuration: hasNoTransition ? '0s' : TRANSITION_TIMING,
            width: `${containerWidth * carouselSlidesTotal}px`,
          }}
        >
          {isEmpty ? (
            <div className="ecl-carousel__empty">
              <span>{emptyLabel}</span>
            </div>
          ) : (
            carouselSlides.map((slide, i) => (
              <div
                aria-hidden={i === currentIndex}
                aria-label={t('SHARED.CAROUSEL.SLIDE_COUNT', { count: currentSlideNumber, total: slides.length })}
                className="ecl-carousel__slide"
                key={`slide${i}`}
                role="group"
                style={{
                  width: `${100 / carouselSlidesTotal}%`,
                }}
              >
                {slide}
              </div>
            ))
          )}
        </div>
      </div>
      {!isEmpty && (
        <div className="ecl-carousel__controls">
          <button aria-hidden="true" className="ecl-carousel__prev" onClick={() => shiftSlide('previous')}>
            <Icon className="ecl-u-d-block" color="inverted" name="corner-arrow" size="m" transformation="rotate-270" />
            <span className="ecl-u-sr-only">{t('SHARED.CAROUSEL.PREVIOUS')}</span>
          </button>
          <button aria-hidden="true" className="ecl-carousel__next" onClick={() => shiftSlide('next')}>
            <Icon className="ecl-u-d-block" color="inverted" name="corner-arrow" size="m" transformation="rotate-90" />
            <span className="ecl-u-sr-only">{t('SHARED.CAROUSEL.NEXT')}</span>
          </button>
          <div className="ecl-container">
            {autoPlay ? (
              <button aria-hidden="true" className="ecl-carousel__toggle" onClick={() => setIsAutoPlaying(!isAutoPlaying)}>
                <Icon color="inverted" name={isAutoPlaying ? 'pause' : 'play'} size="l" />
                <span className="ecl-u-sr-only">{t('SHARED.CAROUSEL.AUTO_PLAY')}</span>
              </button>
            ) : null}
            <div className="ecl-carousel__pagination">
              <span className="ecl-carousel__current">{currentSlideNumber}&nbsp;</span>
              {t('SHARED.CAROUSEL.OF_TOTAL', { total: slides.length })}
            </div>
            <div className="ecl-carousel__navigation">
              {slides.map((_, i) => (
                <button
                  aria-current={currentSlideNumber === i + 1}
                  className="ecl-carousel__navigation-item"
                  key={i}
                  onClick={() => {
                    setCurrentIndex(i + 1);
                    setIsAutoPlaying(false);
                  }}
                >
                  <span className="ecl-u-sr-only">{t('SHARED.CAROUSEL.GO_TO_SLIDE', { count: i + 1 })}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
