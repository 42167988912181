import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import ecLogo from '../../_assets/svg/eu-logo_white.svg';
import { Icon } from '../../_shared';

import './footer.scss';

/**
 * EC Component: Footer
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-site-wide-site-footer--harmonised
 * js: No js
 */

const Footer: FC = () => {
  const { t } = useTranslation();

  const relatedItems = [
    {
      label: t('FOOTER.RESEARCH_AND_INNOVATION.LABEL'),
      link: t('FOOTER.RESEARCH_AND_INNOVATION.LINK'),
    },
    {
      label: t('FOOTER.EXHIBITION.LABEL'),
      link: t('FOOTER.EXHIBITION.LINK'),
    },
    {
      label: t('FOOTER.HORIZON_MAGAZINE.LABEL'),
      link: t('FOOTER.HORIZON_MAGAZINE.LINK'),
    },
  ];

  const linkItemsLeft = [
    {
      label: t('FOOTER.CONTACT.LABEL'),
      link: t('FOOTER.CONTACT.LINK'),
    },
    {
      label: t('FOOTER.SOCIAL.LABEL'),
      link: t('FOOTER.SOCIAL.LINK'),
    },
    {
      label: t('FOOTER.RESOURCES.LABEL'),
      link: t('FOOTER.RESOURCES.LINK'),
    },
  ];

  const linkItemsRight = [
    {
      label: t('FOOTER.LANGUAGE_POLICY.LABEL'),
      link: t('FOOTER.LANGUAGE_POLICY.LINK'),
    },
    {
      label: t('FOOTER.COOKIES.LABEL'),
      link: t('FOOTER.COOKIES.LINK'),
    },
    {
      label: t('FOOTER.PRIVACY_POLICY.LABEL'),
      link: t('FOOTER.PRIVACY_POLICY.LINK'),
    },
    {
      label: t('FOOTER.LEGAL.LABEL'),
      link: t('FOOTER.LEGAL.LINK'),
    },
  ];

  return (
    <footer className="ecl-site-footer" id="footer">
      <div className="ecl-container ecl-site-footer__container">
        <div className="ecl-site-footer__row">
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section">
              <h2 className="ecl-site-footer__title">
                <a className="ecl-link ecl-link--standalone ecl-site-footer__title-link" href={t('FOOTER.TITLE.LINK')}>
                  {t('FOOTER.TITLE.LABEL')}
                </a>
              </h2>
              <div className="ecl-site-footer__description">{t('FOOTER.MANAGED_BY')}</div>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section">
              <a className="ecl-site-footer__title" href={t('FOOTER.CONTACT_US.LINK')}>
                {t('FOOTER.CONTACT_US.LABEL')}
              </a>
            </div>
            <div className="ecl-site-footer__section">
              <h2 className="ecl-site-footer__title ecl-site-footer__title--separator">{t('FOOTER.FOLLOW_US')}</h2>
              <ul className="ecl-site-footer__list ecl-site-footer__list--inline">
                <li className="ecl-site-footer__list-item">
                  <a
                    aria-label={t('SHARED.FACEBOOK')}
                    className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before ecl-site-footer__link"
                    href="https://www.facebook.com/EUScienceInnov"
                  >
                    <Icon name="SvgSocialFacebook" size={1} />
                    <span className="ecl-link__label">{t('SHARED.FACEBOOK')}</span>
                  </a>
                </li>
                <li className="ecl-site-footer__list-item">
                  <a
                    aria-label={t('SHARED.TWITTER')}
                    className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before ecl-site-footer__link"
                    href="https://twitter.com/EUScienceInnov"
                  >
                    <Icon name="SvgSocialTwitter" size={1} />
                    <span className="ecl-link__label">{t('SHARED.TWITTER')}</span>
                  </a>
                </li>
                <li className="ecl-site-footer__list-item">
                  <a
                    aria-label={t('SHARED.LINKEDIN')}
                    className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before ecl-site-footer__link"
                    href="https://www.linkedin.com/showcase/european-commission-joint-research-centre/?originalSubdomain=be"
                  >
                    <Icon name="SvgLinkedin" size={1} />
                    <span className="ecl-link__label">{t('SHARED.LINKEDIN')}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section">
              <a className="ecl-site-footer__title" href={t('FOOTER.ABOUT_US.LINK')}>
                {t('FOOTER.ABOUT_US.LABEL')}
              </a>
            </div>
            <div className="ecl-site-footer__section">
              <h2 className="ecl-site-footer__title ecl-site-footer__title--separator">{t('FOOTER.RELATED_SITES')}</h2>
              <ul className="ecl-site-footer__list">
                {relatedItems.map(({ link, label }) => (
                  <li className="ecl-site-footer__list-item" key={label}>
                    <a
                      aria-label={`${t('FOOTER.LINK_TO_RELATED')} ${label}`}
                      className="ecl-link ecl-link--standalone ecl-site-footer__link"
                      href={link}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="ecl-site-footer__row">
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section">
              <a
                aria-label={t('FOOTER.TITLE.LABEL')}
                className="ecl-link ecl-link--standalone ecl-site-footer__logo-link"
                href={t('FOOTER.TITLE.LINK')}
              >
                <img
                  alt="European Commission logo"
                  className="ecl-site-footer__logo-image-desktop"
                  src={ecLogo}
                  title={t('FOOTER.TITLE.LABEL')}
                />
              </a>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section ecl-footer-standardised__section--split-list">
              <ul className="ecl-site-footer__list">
                {linkItemsLeft.map(({ label, link }) => (
                  <li className="ecl-site-footer__list-item" key={label}>
                    <a
                      aria-label={`${t('FOOTER.LINK_TO')} ${label}`}
                      className="ecl-link ecl-link--standalone ecl-site-footer__link"
                      href={link}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section ecl-footer-standardised__section--split-list">
              <ul className="ecl-site-footer__list">
                {linkItemsRight.map(({ label, link }) => (
                  <li className="ecl-site-footer__list-item" key={label}>
                    <a
                      aria-label={`${t('FOOTER.LINK_TO')} ${label}`}
                      className="ecl-link ecl-link--standalone ecl-site-footer__link"
                      href={link}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
