/* eslint-disable jsx-a11y/role-supports-aria-props */
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '../../../_shared';

type TProps = {
  toggleLanguageMenu: () => void;
};

export const LanguageSelector: FC<TProps> = ({ toggleLanguageMenu }) => {
  const { t } = useTranslation();

  return (
    <button
      aria-controls="language-list-overlay"
      aria-label={t('SHARED.LOCALE.EN')}
      className="ecl-button ecl-button--ghost ecl-site-header-core__language-selector"
      data-ecl-language-selector
      onClick={() => {
        toggleLanguageMenu();
      }}
    >
      <span className="ecl-site-header-core__language-icon">
        <Icon className="ecl-site-header-core__icon" name="language" size="s" />
        <span className="ecl-site-header-core__language-code">{t('SHARED.LOCALE.EN_CODE')}</span>
      </span>
      {t('SHARED.LOCALE.EN')}
    </button>
  );
};
