import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Config } from '../../config';
import Icon from '../icon/Icon';
import './socials.scss';

const Socials: FC = () => {
  const { t } = useTranslation();
  const socialMediaUrls = Config.socialMediaUrls(window.location.href);

  return (
    <>
      <a className="socials__twitter" href={socialMediaUrls.twitter} rel="noreferrer" target="_blank">
        <Icon className="icon" name="SvgTwitter" />
        {t('SHARED.TWITTER')}
      </a>
      <a className="socials__facebook" href={socialMediaUrls.facebook} rel="noreferrer" target="_blank">
        <Icon className="icon" name="SvgFacebook" />
        {t('SHARED.FACEBOOK')}
      </a>
      <a className="socials__linkedin" href={socialMediaUrls.linkedin} rel="noreferrer" target="_blank">
        <Icon className="icon" name="SvgLinkedin" />
        {t('SHARED.LINKEDIN')}
      </a>
      <button className="socials__copy" onClick={() => navigator.clipboard.writeText(window.location.href)}>
        <Icon className="icon" name="SvgCopy" />
        {t('SHARED.COPY_LINK')}
      </button>
    </>
  );
};
export default Socials;
