import { BaseSyntheticEvent, ElementType, forwardRef, ReactNode, useCallback } from 'react';

import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';

import { shouldOpenInNewTab, isPlatformLink } from '../../_utils/linkHelpers';
import Icon, { TIconSize, TIconTransformation, TIconType } from '../icon/Icon';
import Spinner from '../spinner/Spinner';

import './button.scss';

type TButtonVariant =
  | 'primary'
  | 'secondary'
  | 'cta'
  | 'ghost'
  | 'text'
  | 'primary-link'
  | 'secondary-link'
  | 'cta-link'
  | 'plain-link'
  | 'wrapper';

type Props = {
  children?: string | ReactNode;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  href?: string;
  icon?: TIconType;
  iconPosition?: 'before' | 'after';
  iconSize?: TIconSize;
  iconTransformation?: TIconTransformation;
  isNavLink?: boolean;
  loading?: boolean;
  onClick?: (event?: BaseSyntheticEvent) => void;
  shouldOpenInSameTab?: boolean;
  stopPropagation?: boolean;
  theme?: TButtonVariant;
  type?: 'button' | 'submit' | 'reset';
};

type TForwardRef = ReactNode;

const Button = forwardRef<TForwardRef, Props>(
  (
    {
      hideLabel,
      children,
      containerClassName = '',
      className = '',
      disabled,
      href,
      icon,
      iconSize = 'm',
      iconPosition = 'after',
      iconTransformation,
      isNavLink,
      loading,
      onClick,
      theme = 'primary',
      type = 'button',
      shouldOpenInSameTab = false,
      stopPropagation = false,
      ...otherProps
    },
    ref,
  ) => {
    const isLink = theme.includes('link');
    const cssClasses = classNames(className, {
      'button--loading': loading,
      'ecl-button': !isLink,
      'ecl-button--call': theme === 'cta',
      'ecl-button--ghost': theme === 'ghost',
      'ecl-button--primary': theme === 'primary',
      'ecl-button--secondary': theme === 'secondary',
      'ecl-button--text': theme === 'text',
      'ecl-link': isLink,
      'ecl-link--cta': theme === 'cta-link',
      'ecl-link--primary': theme === 'primary-link',
      'ecl-link--secondary': theme === 'secondary-link',
    });
    const Component: ElementType =
      href && !disabled ? (shouldOpenInNewTab(href) || isPlatformLink(href) ? 'a' : isNavLink ? NavLink : Link) : 'button';
    let actionProp: Record<string, unknown> = {};

    const callback = useCallback(
      event => {
        // Do not perform custom actions when the user has a modifier key pressed on an anchor
        if (
          (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey || event.button !== 0) &&
          event.currentTarget.nodeName.toUpperCase() === 'A'
        )
          return;

        event.preventDefault();
        if (stopPropagation) {
          event.stopPropagation();
        }

        onClick?.(event);
      },
      [onClick, stopPropagation],
    );

    if (href && !disabled) {
      if (shouldOpenInNewTab(href) || isPlatformLink(href)) {
        actionProp = {
          'aria-disabled': disabled,
          href: disabled ? '#' : href,
          onClick: onClick ? callback : stopPropagation ? event => event.stopPropagation() : undefined,
          rel: shouldOpenInNewTab(href) && !shouldOpenInSameTab ? 'noopener noreferrer' : undefined,
          target: shouldOpenInNewTab(href) && !shouldOpenInSameTab ? '_blank' : undefined,
        };
      } else {
        actionProp = { 'aria-disabled': disabled, onClick, to: disabled ? '#' : href };
      }
    } else {
      actionProp = { disabled, onClick, type };
    }

    const inlineSpinner = loading && (
      <div className="spinner-wrapper">
        <Spinner theme={theme.includes('primary') ? 'negative' : 'primary'} />
      </div>
    );

    const inlineIcon = icon && (
      <Icon
        className={classNames('ecl-button__icon', { [`ecl-button__icon--${iconPosition}`]: !hideLabel })}
        name={icon}
        size={iconSize}
        transformation={iconTransformation}
      />
    );

    return (
      <Component {...otherProps} {...actionProp} className={cssClasses} ref={ref} to={actionProp?.to}>
        <span className={classNames('ecl-button__container', containerClassName)}>
          {iconPosition === 'before' && inlineIcon}
          {hideLabel ? (
            <span className="visually-hidden">{children}</span>
          ) : typeof children === 'string' ? (
            <span className="ecl-button__label">{children}</span>
          ) : (
            children
          )}
          {iconPosition === 'after' && inlineIcon}
          {inlineSpinner}
        </span>
      </Component>
    );
  },
);
export default Button;
