/* eslint-disable jsx-a11y/role-supports-aria-props */
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../../_shared';

type TProps = {
  isLanguageMenuOpen: boolean;
  toggleLanguageMenu: (nextValue?: boolean) => void;
};

export const LanguageList: FC<TProps> = ({ toggleLanguageMenu, isLanguageMenuOpen }) => {
  const { t } = useTranslation();

  return (
    <div
      aria-expanded={!isLanguageMenuOpen}
      aria-labelledby="ecl-language-list__title"
      aria-modal={!isLanguageMenuOpen}
      className="ecl-language-list ecl-language-list--overlay"
      data-ecl-language-list-overlay
      hidden={!isLanguageMenuOpen}
      id="language-list-overlay"
      role="dialog"
    >
      <div className="ecl-language-list__container ecl-container">
        <div className="ecl-row">
          <div className="ecl-language-list__close ecl-col-12 ecl-col-l-8 ecl-offset-l-2">
            <Button
              className="ecl-language-list__close-button"
              icon="close"
              iconPosition="after"
              iconSize="s"
              onClick={() => {
                toggleLanguageMenu(false);
              }}
              theme="ghost"
            >
              {t('SHARED.BUTTONS.CLOSE')}
            </Button>
          </div>
          <div className="ecl-language-list__title ecl-col-12 ecl-col-l-8 ecl-offset-l-2" id="ecl-language-list__title">
            <Icon className="ecl-language-list__title-icon" name="generic-lang" size="m" />
            {t('SITE_HEADER.SELECT_LANGUAGE')}
          </div>
        </div>
        <div className="ecl-row ecl-language-list__eu">
          <div className="ecl-language-list__category ecl-col-12 ecl-col-l-8 ecl-offset-l-2">{t('SITE_HEADER.EU_LANGUAGES')}</div>
          <div className="ecl-language-list__column ecl-col-12 ecl-col-l-4 ecl-offset-l-2">
            <ul className="ecl-language-list__list">
              <li className="ecl-language-list__item ecl-language-list__item--is-active">
                <a
                  className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-after ecl-language-list__link"
                  href="/component-library/example"
                  hrefLang={t('SHARED.LOCALE.EN_CODE')}
                  lang={t('SHARED.LOCALE.EN_CODE')}
                  rel="alternate"
                >
                  <span className="ecl-link__label">{t('SHARED.LOCALE.EN')}</span>
                  <Icon className="ecl-link__icon" name="check" size="xs" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
