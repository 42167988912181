import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import JoinPlatformImage from '../../_assets/images/join-platform.png';
import { useAuthContext } from '../../auth/_context';
import { Config } from '../../config';
import Button from '../button/Button';
import Title from '../title/Title';

import './joinPlatform.scss';

const JoinPlatform: FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn, loginRedirectUrl } = useAuthContext();

  if (isLoggedIn) return null;
  return (
    <section className="join-platform ecl-container">
      <Title>{t('HOW_IT_WORKS.JOIN_PLATFORM.TITLE')}</Title>
      <div className="join-platform__content">
        <div>
          <p>{t('HOW_IT_WORKS.JOIN_PLATFORM.DESCRIPTION')}</p>
          <Button href={loginRedirectUrl} theme="secondary">
            {t('SHARED.LOG_IN')}
          </Button>
          <Button
            href={Config.registerUrl}
            icon="corner-arrow"
            iconSize={1}
            iconTransformation="rotate-90"
            shouldOpenInSameTab
            theme="cta"
          >
            {t('SHARED.REGISTER')}
          </Button>
        </div>

        <img alt="" src={JoinPlatformImage} />
      </div>
    </section>
  );
};
export default JoinPlatform;
