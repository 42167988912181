import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Socials } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import AuthModal from '../../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../../auth/_context';
import { TEvent } from '../../_models';
import { useAddToInterests, useDeleteFromInterests } from '../../_queries';
import InviteModal from '../inviteModal/InviteModal';

import './engage.scss';

type TProps = {
  event: TEvent;
};

const Engage: FC<TProps> = ({ event }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();

  const { mutate: addToInterests } = useAddToInterests();
  const { mutate: deleteFromInterests } = useDeleteFromInterests();

  function handleInterests() {
    if (!isLoggedIn) return ModalOpener.instance.open({ render: () => <AuthModal /> });
    if (event.interested) deleteFromInterests({ eventId: event.id });
    else addToInterests({ eventId: event.id });
  }

  return (
    <div className="engage ecl-container">
      <div>
        <Button
          icon="SvgInvite"
          onClick={() => ModalOpener.instance.open({ render: () => (isLoggedIn ? <InviteModal /> : <AuthModal />) })}
          theme="secondary"
        >
          {t('EVENT.ENGAGE.INVITE')}
        </Button>
        <Button icon={event.interested ? 'SvgStarChecked' : 'SvgStarUnChecked'} onClick={handleInterests}>
          {t('EVENT.ENGAGE.INTERESTED')}
        </Button>
      </div>
      <div className="engage__socials">
        <span>{t('EVENT.ENGAGE.SHARE')}</span>
        <Socials />
      </div>
    </div>
  );
};
export default Engage;
