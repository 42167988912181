import { FC, useLayoutEffect, useRef } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useToggle } from '../../_hooks';
import { Spinner } from '../../_shared';
import { useAuthContext } from '../../auth/_context';
import { Menu, TRoute } from '../../ec/menu/Menu';
import PageBanner from '../../ec/pageBanner/PageBanner';
import HubsRoutes from '../../hubs/HubsRoutes';
import Participants from '../../participants/Participants';
import Programme from '../../programme/Programme';
import ProjectsRoutes from '../../projects/ProjectsRoutes';
import LiveSessions from '../../sessions/livesessions/Livesessions';
import Speakers from '../../speakers/Speakers';
import Workshops from '../../workshops/Workshops';
import { DetailOverview, Engage, ExtraPage } from '../_components';
import { useEventContext } from '../_context/EventContext';
import { Tab } from '../_models';
import './detail.scss';

type TFullRoute = TRoute & {
  element?: JSX.Element;
  order: number;
  tab: Tab;
};

const Detail: FC = () => {
  const { t } = useTranslation();
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const { isLoggedIn } = useAuthContext();
  const { event, isLoading } = useEventContext();
  const ref = useRef<HTMLElement>();

  const getRoutes = (): TFullRoute[] => {
    const routes: TFullRoute[] = [
      { element: <DetailOverview />, label: t('EVENT.NAVIGATION.OVERVIEW'), link: 'overview', order: 1, tab: 'OVERVIEW' },
      { element: <Programme />, label: t('EVENT.NAVIGATION.PROGRAMME'), link: 'programme', order: 2, tab: 'PROGRAMME' },
      { element: <Workshops />, label: t('EVENT.NAVIGATION.WORKSHOPS'), link: 'workshops', order: 4, tab: 'WORKSHOPS' },
      { element: <Speakers />, label: t('EVENT.NAVIGATION.SPEAKERS'), link: 'speakers', order: 5, tab: 'SPEAKERS' },
      {
        element: <Participants />,
        label: t('EVENT.NAVIGATION.PARTICIPANTS'),
        link: 'participants',
        order: 6,
        tab: 'PARTICIPANTS',
      },
      { element: <HubsRoutes />, label: t('EVENT.NAVIGATION.HUBS'), link: 'hubs', order: 7, tab: 'HUBS' },
      { element: <ProjectsRoutes />, label: t('EVENT.NAVIGATION.PROJECTS'), link: 'projects', order: 8, tab: 'PROJECTS' },
    ];

    if (isLoggedIn) {
      routes.push({
        element: <LiveSessions />,
        label: t('EVENT.NAVIGATION.LIVE_SESSIONS'),
        link: 'live-sessions',
        order: 3,
        tab: 'LIVESESSION',
      });
    }
    if (event?.exhibitionUrl) {
      routes.push({ label: t('FOOTER.EXHIBITION.LABEL'), link: event.exhibitionUrl ?? '#', order: 9, tab: 'EXHIBITION' });
    }
    if (event?.extraPage) {
      routes.push({ element: <ExtraPage />, label: event.extraPage.title, link: 'extra', order: 10, tab: 'EXTRA' });
    }
    return routes.filter(route => !event.hiddenTabs?.includes(route.tab));
  };

  useLayoutEffect(() => {
    if (ref.current) ref.current.style.top = `${document.getElementById('site-header').clientHeight}px`;
  }, [ref.current, isLoading]);

  if (isLoading) return <Spinner />;
  if (!event && !isLoading) return null;
  return (
    <>
      <Helmet>
        <title>{t('SHARED.NAVIGATION.EVENTS')}</title>
      </Helmet>

      <PageBanner
        attendingInfo={{ attendeesCount: event.attendeesCount, eventId: event.id, isUserAttending: event.attending }}
        description={event.shortDescription}
        endDate={event.endDate}
        image={event.headerImage?.url}
        key={event.id}
        label={t('EVENTS.VIEW_EVENT')}
        registrationUrl={event.registrationUrl}
        startDate={event.startDate}
        title={event.title}
        type="shade"
      />
      <Menu className="ecl-menu__sticky" isMenuOpen={isMenuOpen} ref={ref} routes={getRoutes()} toggleMenu={toggleMenu} />

      <Engage event={event} />

      <Routes>
        {getRoutes()
          .sort((a, b) => a.order - b.order)
          .map(({ element, link, tab }) => element && <Route element={element} key={tab} path={link} />)}
        <Route element={<ProjectsRoutes />} path="projects/*" />
        <Route element={<HubsRoutes />} path="hubs/*" />
        <Route element={<Navigate to="overview" />} path="*" />
      </Routes>
    </>
  );
};
export default Detail;
