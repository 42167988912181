import { FC } from 'react';

import icons from '@ecl/resources-ec-icons/dist/sprites/icons.svg';
import classnames from 'classnames';

import * as ICONS from '../../_assets/svg';

import { TEcIconType, ecIcons } from './ecIconList';
import './icon.scss';

type TAssetIconType = keyof typeof ICONS;
export type TIconType = TAssetIconType | TEcIconType;

export type TIconSize = '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | 'fluid' | number;
export type TIconTransformation = 'rotate-90' | 'rotate-180' | 'rotate-270' | 'flip-horizontal' | 'flip-vertical';

type TProps = {
  className?: string;
  color?: 'inverted' | 'primary';
  label?: string;
  name: TIconType;
  size?: TIconSize;
  transformation?: TIconTransformation;
};

const Icon: FC<TProps> = ({ size = 'm', name, className = '', color, transformation, label }) => {
  const isEcIcon = ecIcons.some(ecIcon => ecIcon === name);
  if (!isEcIcon && !ICONS[name]) return null;
  const Component = isEcIcon ? 'svg' : ICONS[name];

  return (
    <>
      <Component
        aria-hidden="true"
        className={classnames('ecl-icon', className, {
          [`ecl-icon--${size}`]: typeof size === 'string',
          [`ecl-icon--${color}`]: color,
          [`ecl-icon--${transformation}`]: transformation,
        })}
        focusable="false"
        style={typeof size === 'number' ? { fill: 'none', height: `${size}rem`, width: `${size}rem` } : {}}
      >
        {isEcIcon ? <use xlinkHref={`${icons}#${name}`}></use> : undefined}
      </Component>
      {label ? <span className="visually-hidden">{label}</span> : undefined}
    </>
  );
};

export default Icon;
