import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { TDocument } from '../../_models';
import Button from '../button/Button';
import Icon from '../icon/Icon';

import './document.scss';

type TProps = {
  document: TDocument;
};

const Document: FC<TProps> = ({ document }) => {
  const { t } = useTranslation();
  const getFileSize = (size: number): string => {
    if (size >= 1000) return `${Math.round(size / 100) / 10} KB`;
    if (size >= 1000000) return `${Math.round(size / 100000) / 10} MB`;
    return `${size} byte`;
  };

  const getContentType = (contentType: string): string => {
    switch (contentType) {
      case 'application/pdf':
        return t('FILE_TYPE.PDF');
      case 'text/csv':
        return t('FILE_TYPE.CSV');
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return t('FILE_TYPE.DOC');
      case 'application/epub+zip':
        return t('FILE_TYPE.EPUB');
      case 'application/application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return t('FILE_TYPE.PPT');
      case 'application/rtf':
        return t('FILE_TYPE.RTF');
      case 'text/plain':
        return t('FILE_TYPE.TXT');
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return t('FILE_TYPE.XLS');
      default:
        return t('FILE_TYPE.OTHER');
    }
  };

  return (
    <div className="document">
      <div>
        <Icon name="file" size="l" />
        <div className="document__content">
          <span className="document__content__title">{document.title}</span>
          <span className="document__content__file-info">{`(${getFileSize(document.file.size)} - ${getContentType(
            document.file.contentType,
          )})`}</span>
        </div>
      </div>
      <Button href={document.file.url} icon="download" iconSize="xs" theme="secondary">
        {t('SHARED.BUTTONS.DOWNLOAD')}
      </Button>
    </div>
  );
};

export default Document;
