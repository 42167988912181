import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import ecLogo from '../../_assets/svg/eu-logo_grey.svg';
import { useToggle } from '../../_hooks';
import { Button } from '../../_shared';
import { useAuthContext } from '../../auth/_context';
import { Config } from '../../config';
import { useNotificationsContext } from '../../notifications/_context/NotificationsContext';
import { Menu, TRoute } from '../menu/Menu';
import PageHeader from '../pageHeader/PageHeader';

import { LanguageList, LanguageSelector, Login } from './components';

import './siteHeader.scss';

/**
 * EC Component: Siteheader
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-site-headers-core--logged-in
 * js: https://github.com/ec-europa/europa-component-library/tree/v3-dev/src/implementations/vanilla/components/site-header-core
 */

const SiteHeader: FC = () => {
  const [isLoginMenuOpen, toggleLoginMenu] = useToggle(false);
  const [isLanguageOpen, toggleLanguage] = useToggle(false);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();
  const { countUnSeenNotifications } = useNotificationsContext();

  const routes: TRoute[] = [
    { label: t('SHARED.NAVIGATION.HOME'), link: '/home' },
    { label: t('SHARED.NAVIGATION.EVENTS'), link: '/events' },
    { label: t('SHARED.NAVIGATION.COMMUNITY'), link: '/community' },
    { label: t('SHARED.NAVIGATION.HOW_IT_WORKS'), link: '/how-it-works' },
  ];

  const handleLoginMenu = (nextValue?: boolean) => {
    toggleMenu(false);
    toggleLanguage(false);
    toggleLoginMenu(nextValue);
  };

  const handleLanguageMenu = (nextValue?: boolean) => {
    toggleMenu(false);
    toggleLoginMenu(false);
    toggleLanguage(nextValue);
  };

  const handleMenuToggle = (nextValue?: boolean) => {
    toggleLoginMenu(false);
    toggleLanguage(false);
    toggleMenu(nextValue);
  };

  return (
    <>
      <header className="ecl-site-header-core site-header" id="site-header">
        <div className="ecl-site-header-core__container ecl-container">
          <div className="ecl-site-header-core__top">
            <a
              aria-label={t('SITE_HEADER.EC_HOME')}
              className="ecl-link ecl-link--standalone ecl-site-header-core__logo-link"
              href="https://commission.europa.eu/index_en"
            >
              <img
                alt={t('SITE_HEADER.EC_LOGO_ALT')}
                className="ecl-site-header-core__logo-image ecl-site-header-core__logo-image-desktop"
                src={ecLogo}
                title={t('SITE_HEADER.EC_HOME')}
              />
            </a>
            <div className="ecl-site-header-core__action">
              <Login isLoginMenuOpen={isLoginMenuOpen} toggleLoginMenu={handleLoginMenu} />
              {isLoggedIn ? (
                <Button
                  className="ecl-button ecl-button--ghost notifications-indicator"
                  href="notifications"
                  onClick={() => toggleLoginMenu(false)}
                  theme="plain-link"
                >
                  {countUnSeenNotifications > 0 && (
                    <span className="notifications-indicator__counter">{countUnSeenNotifications}</span>
                  )}
                  {t('SHARED.NAVIGATION.NOTIFICATIONS')}
                </Button>
              ) : (
                <a className="ecl-button ecl-button--ghost ecl-site-header-core__login-toggle" href={Config.registerUrl}>
                  {t('SHARED.REGISTER')}
                </a>
              )}
              <LanguageSelector toggleLanguageMenu={handleLanguageMenu} />
            </div>
          </div>
        </div>
      </header>
      <PageHeader />
      <Menu isMenuOpen={isMenuOpen} routes={routes} toggleMenu={handleMenuToggle} />
      <LanguageList isLanguageMenuOpen={isLanguageOpen} toggleLanguageMenu={handleLanguageMenu} />
    </>
  );
};

export default SiteHeader;
