export const ecIcons = [
  'audio',
  'back',
  'book',
  'brochure',
  'budget',
  'calendar',
  'check-filled',
  'check',
  'close-filled',
  'close',
  'copy',
  'corner-arrow',
  'data',
  'digital',
  'download',
  'edit',
  'energy',
  'error',
  'euro',
  'external-events',
  'external',
  'faq',
  'feedback',
  'file',
  'fullscreen',
  'gear',
  'generic-lang',
  'global',
  'growth',
  'hamburger',
  'image',
  'infographic',
  'information',
  'language',
  'list',
  'livestreaming',
  'location',
  'log-in',
  'logged-in',
  'minus',
  'multiple-files',
  'organigram',
  'package',
  'pause-filled',
  'pause',
  'play-filled',
  'play',
  'plus',
  'presentation',
  'print',
  'regulation',
  'rss',
  'search',
  'share',
  'solid-arrow',
  'spinner',
  'spreadsheet',
  'success',
  'tag',
  'trash',
  'video',
  'warning',
] as const;
export type TEcIconType = (typeof ecIcons)[number];
