import { FC } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Icon } from '../../_shared';
import './pageHeader.scss';
import { useGetEvent } from '../../events/_queries';
import { useGetPost } from '../../posts/_queries';

/**
 * EC Component: PageHeader
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-page-headers-core--default
 * js: No js
 */

const PageHeader: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const breadCrumbs = pathname.split('/').filter(crumb => crumb !== 'home');
  const { data: event } = useGetEvent(breadCrumbs[1] === 'events' ? pathname.split('/')?.[2] : '');
  const { data: post } = useGetPost(breadCrumbs[2] === 'posts' ? pathname.split('/')?.[3] : '');

  const getCrumbLabel = (crumb: string, index: number) => {
    if (index === 2 && breadCrumbs[1] === 'events') {
      return event?.title ?? t('EVENT.NAVIGATION.EVENT');
    }
    if (index === 2 && breadCrumbs[1] === 'community') {
      return t(`COMMUNITY.NAVIGATION.${crumb.toUpperCase().replace(/-/g, '_')}` as 'COMMUNITY.NAVIGATION.CONVERSATIONS');
    }
    if (index === 3 && breadCrumbs[1] === 'events') {
      return t(`EVENT.NAVIGATION.${crumb.toUpperCase().replace(/-/g, '_')}` as 'EVENT.NAVIGATION.OVERVIEW');
    }
    if (index === 3 && breadCrumbs[2] === 'posts') {
      return post?.title ?? t('COMMUNITY.POSTS.DETAIL');
    }
    if (index === 4 && breadCrumbs[3] === 'hubs') {
      return t('HUBS.DETAIL.NAVIGATION_TITLE');
    }
    if (index === 4 && breadCrumbs[3] === 'projects') {
      return t('PROJECTS.DETAIL.NAVIGATION_TITLE');
    }
    return t(`SHARED.NAVIGATION.${crumb.toUpperCase().replace(/-/g, '_')}` as 'SHARED.NAVIGATION.HOME');
  };

  return (
    <div className="ecl-page-header-core">
      <div className="ecl-container">
        <nav aria-label={t('PAGE_HEADER.BREADCRUMBS_ARIA_LABEL')}>
          <ol className="ecl-breadcrumb-core__container">
            {breadCrumbs.map((crumb, i) => {
              const isOnly = breadCrumbs.length === 1;
              const isLast = i === breadCrumbs.length - 1;

              return (
                <li
                  className={classNames('ecl-breadcrumb-core__segment', {
                    'ecl-breadcrumb-core__current-page': isLast,
                  })}
                  key={`crumb-${crumb}-${i}`}
                >
                  {isLast ? (
                    isOnly ? (
                      t('SHARED.NAVIGATION.HOME')
                    ) : (
                      getCrumbLabel(crumb, i)
                    )
                  ) : (
                    <>
                      <NavLink
                        className="ecl-link ecl-link--standalone ecl-link--negative ecl-link--no-visited ecl-breadcrumb-core__link"
                        to={breadCrumbs.filter((_, crumbIndex) => crumbIndex <= i).join('/')}
                      >
                        {i ? getCrumbLabel(crumb, i) : t('SHARED.NAVIGATION.HOME')}
                      </NavLink>
                      <Icon className="ecl-breadcrumb-core__icon" name="corner-arrow" size="2xs" transformation="rotate-90" />
                    </>
                  )}
                </li>
              );
            })}
          </ol>
        </nav>
        <hr />
        <h1 className="ecl-page-header-core__title">{t('PAGE_HEADER.TITLE')}</h1>
        <div className="ecl-page-header-core__description-container">
          <p className="ecl-page-header-core__description">{t('PAGE_HEADER.DESCRIPTION')}</p>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
