import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Icon, Title } from '../../../_shared';
import './introCommunity.scss';

const IntroCommunity: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="intro-community ecl-container">
      <Title>{t('COMMUNITY.ABOUT.TITLE')}</Title>

      <div className="intro-community__wrapper ecl-container">
        <div className="intro-community__wrapper__info">
          <p>{t('COMMUNITY.ABOUT.INFO_ONE')}</p>
          <p>{t('COMMUNITY.ABOUT.INFO_TWO')}</p>
          <span>
            <p>{t('COMMUNITY.ABOUT.MORE_INFO')}</p>
            <Button href="/how-it-works" icon="SvgChevron" iconPosition="after" iconSize={1.2} theme="ghost">
              {t('COMMUNITY.ABOUT.HOW_IT_WORKS')}
            </Button>
          </span>
        </div>

        <div className="intro-community__wrapper__stats">
          <div className="intro-community__wrapper__stats__posts">
            <Icon name="SvgPosts" size={2.1} />
            <p className="count">{t('COMMUNITY.ABOUT.NRPOSTS')}</p>
            <h4>{t('COMMUNITY.ABOUT.POSTS')}</h4>
            <p>{t('COMMUNITY.ABOUT.POSTS_INFO')}</p>
          </div>
          <div className="intro-community__wrapper__stats__members">
            <Icon name="SvgMembers" size={2} />
            <p className="count">{t('COMMUNITY.ABOUT.NRMEMBERS')}</p>
            <h4>{t('COMMUNITY.ABOUT.MEMBERS')}</h4>
            <p>{t('COMMUNITY.ABOUT.MEMBERS_INFO')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroCommunity;
